import React from 'react';
import './Footer.scss';

// export default function Footer({ ...rest }) {
//   return <footer className={'footer'} {...rest} />;
// }

export default function Footer({ ...rest }) {
  return (
    <footer className={'footer'} {...rest}>
      All rights reserved by Vitome&reg;
    </footer>
  );
}