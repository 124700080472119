export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Patient List',
    path: '/patientlist',
    icon: 'floppy'
  }

  ];
