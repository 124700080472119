import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Export,
  HeaderFilter,
  Selection,
  DataGridTypes
} from 'devextreme-react/data-grid';
import HtmlEditor, {
  TableContextMenu,
  Toolbar,
  Item,
} from 'devextreme-react/html-editor';
import { Button } from 'devextreme-react';
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { backendlink } from '../../config';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import { useWavesurfer } from '@wavesurfer/react';
import './patientrecords.css'


// import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import WaveSurfer from 'wavesurfer.js'
// import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@7/dist/wavesurfer.esm.js'

export default function PatientRecordsPage() {

  const { user } = useAuth();

  const [users, setUsers] = useState([]);
  const [patient, setpatient] = useState('');
  const [selectedRecord, setselectedRecord] = useState('')
  const [waveform, setWaveform] = useState([]);
  const [waveformIsOn, setWaveformIsOn] = useState([]);

  const containerRef = useRef(null)

  // const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
  //   container: containerRef,
  //   height: 100,
  //   waveColor: 'rgb(200, 0, 200)',
  //   progressColor: 'rgb(100, 0, 100)',
  //   url: selectedRecord,
  //   // plugins: useMemo(() => [Timeline.create()], []),
  // })

  const location = useLocation();

  // const onSelectionChanged = useCallback(({ selectedRowsData }) => {
  //   const data = selectedRowsData[0];
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(backendlink + '/doctor/getmedicalrecord',
  //         {
  //           uid: selectedRowsData[0].uid,
  //           token: selectedRowsData[0].token
  //         },
  //         {
  //           headers: {
  //             token: user.token
  //           }
  //         });
  //       const temp = "data:audio/wav;base64," + response.data.result.heartsound[0].heartsound;
  //       // console.log("HHH", temp)
  //       setselectedRecord(temp)
  //     } catch (error) {
  //       console.log(error);
  //       alert(error);
  //     }
  //   };
  //   fetchData();
  // }, []);



  function getWaveformData(uid, token, rowIndex) {
    const handleClick = (e) => {
      e.preventDefault();

      const fetchData = async () => {
        // console.log(waveformIsOn[rowIndex])
        if (waveformIsOn[rowIndex] === undefined || waveformIsOn[rowIndex] === null || waveformIsOn[rowIndex] == false) {
          setWaveformIsOn({ ...waveformIsOn, [rowIndex]: true });
          try {
            const response = await axios.post(backendlink + '/doctor/getmedicalrecord',
              {
                uid, token
              },
              {
                headers: {
                  token: user.token
                }
              });
            if (response.data.errorno == 0){
            const temp = "data:audio/wav;base64," + response.data.result.heartsound[0].heartsound;
            setWaveform({ ...waveform, [rowIndex]: temp });


            const ws = WaveSurfer.create({
              container: '#waveform-' + rowIndex,
              height: 100,
              cursorWidth: 0,
              waveColor: 'red',
              progressColor: 'red',
              // normalize:true,
              url: temp
            });
            // const wsRegions = ws.registerPlugin(RegionsPlugin.create())
            // wsRegions.disableDragSelection()
            // console.log(user)
            }
            else{
              alert(response.data.errmessage)
            }
          } catch (error) {
            // console.log(error);
            alert(error);
          }
        }
      };
      fetchData();
    }

    return (
      <button onClick={handleClick} style={{ backgroundColor: '#ff5722', color: 'white', padding: '10px 20px', borderRadius: '5px', border: "none", marginBottom: "10px", cursor: 'pointer' }}>
        Heartsound
      </button>
    );
  }

  const waveformCell = (cellData) => (
    <div>
      {getWaveformData(cellData.data.uid, cellData.data.token, cellData.rowIndex)}
      <br />
      {waveform[cellData.rowIndex] && <audio controls style={{ width: "100%" }} controlsList="noplaybackrate">
        <source src={waveform[cellData.rowIndex]} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>}
    </div>
  );


  useEffect(() => {
    setpatient(location.state.name); // get id

    const fetchData = async () => {
      try {
        const response = await axios.post(backendlink + '/doctor/getmedicalinfo',
          {
            usertoken: location.state.userToken
          },
          {
            headers: {
              token: user.token
            }
          });
        if (response.data.errorno == 0){

        const temp = response.data.result;
        const convertedData = temp.map(item => {
          const date = new Date(item.submitdate);
          const formattedDate = date.toLocaleDateString('en-US');
          const formattedTime = date.toLocaleTimeString('en-US');

          const date1 = new Date(item.timestamp * 1000);
          const formattedDate1 = date1.toLocaleDateString('en-US');
          const formattedTime1 = date1.toLocaleTimeString('en-US');

          // console.log("format", formattedDate1, formattedTime1)

          var formattedDetails = "";
          if (Array.isArray(item.bpm) && item.bpm.length === 0) {
            formattedDetails = "Incomplete auscultation";
          } else {
            const ClassificationResult = item.classification[0].classification === 0 ? "Normal" : item.classification[0].classification === 1 ? "Abnormal" : "Failed to calculate";
            formattedDetails = item.bpm[0].bpm !== -1 ? ("Heart rate: " + item.bpm[0].bpm) + " bpm <br /> Classification: " + ClassificationResult : ("Heart rate: Failed to calculate <br /> Classification: " + ClassificationResult);
          }

          // console.log("sdasda",formattedOutput)
          // const formattedSpecificDetails = formattedOutput + "<br />" + formattedDetails;
          return { ...item, shareddate: formattedDate+" "+ formattedTime, recorddate: formattedDate1+" "+formattedTime1, Details: formattedDetails };
        });
        // console.log("ss", convertedData.reverse())
        setUsers(convertedData.reverse());
        }
        else{
          alert(response.data.errmessage);
        }
      } catch (error) {
        // console.log(error);
        alert(error);
      }
    };

    fetchData();
  }, []);


  const DataRow = (rowInfo) => (
    <React.Fragment>
      <tr role="row" >
      <td role="gridcell">{rowInfo.data.labelid}</td>
        <td role="gridcell">{rowInfo.data.shareddate}</td>
        <td role="gridcell">{rowInfo.data.recorddate}</td>
        <td role="gridcell">{React.createElement("div", { dangerouslySetInnerHTML: { __html: rowInfo.data.Details } })}</td>
        <td role="gridcell">{waveformCell(rowInfo)}</td>
      </tr>
      <tr className="waveform-row">
        <td colSpan={6}>
          <div id={"waveform-" + rowInfo.rowIndex} />
        </td>
      </tr>
    </React.Fragment>
  );


  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}><strong>Patient:</strong> {patient} <strong>   Email:</strong> {location.state.email}<strong>  <strong>   Gender :</strong> M    Phone:</strong> +852 54549444</h2>
      <h2 className={'content-block'}><strong>Patient ID:</strong> P-233 <strong>Age:</strong> 25 <strong>   Linked Date :</strong> 12/12/2023 <strong> Hospital:</strong> Queen Mary Hospital</h2>  */}
      <table className="custom-table">
        <thead>
          <tr>
            <th>Patient:</th>
            <td>{patient}</td>
            <th>Email:</th>
            <td>{location.state.email}</td>
            <th>Age:</th>
            <td>42</td>
            <th>Gender:</th>
            <td>Male</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Patient ID:</th>
            <td>P-233</td>
            <th>Phone:</th>
            <td> {location.state.phone} </td>
            <th>Linked Date:</th>
            <td>12/12/2023</td>
            <th>Clinic/Hospital:</th>
            <td>Queen Mary Hospital</td>
          </tr>
        </tbody>
      </table>
      <DataGrid
        dataSource={users}
        dataRowRender={DataRow}>
        {/* <Column dataField="activity" /> */}
        <Column dataField="ID" width="10%" allowSorting={false} />
        <Column dataField="SharedDate" width="15%" allowSorting={false} />
        <Column dataField="RecordDate" width="15%" allowSorting={false} />
        <Column dataField="Details" width="30%" allowSorting={false} />
        <Column caption="Waveform" minWidth="320" width="30%" allowSorting={false} />
        <Pager allowedPageSizes={[5, 10]} showPageSizeSelector={true} />
        <Paging defaultPageSize={5} />
        <Selection mode="single" />
      </DataGrid>
    </React.Fragment>
  );
}